<template>
    <div class="wrapper_main">
        <main class="content">
                <div class="map" style="max-height: 388px; border-radius: var(--border-radius-7); overflow: hidden">
                    <div id="map" style="width: 100%; height: 410px"></div>
                </div>
        </main>
    </div>
</template>

<script>


export default {
    props: ["name"],
    name: "MarkersMap",
    components: {

    },
    data() {
        return {
            // markers:[]
        };
    },
    computed: {

    },
    

    async mounted() {
        let markers=[]
        if(this.name=="devices"){
            if (this.$store.state.devices.length<1){
                await this.$store.dispatch("GET_DEVICES");
                console.log("GET_DEVICES");
            };
            console.log(this.$store.state.devices);
            this.$store.state.devices.forEach(d=>{
                if(d.location && d.location.lat && d.location.lng){
                    markers.push({lat:d.location.lat,lng:d.location.lng})
                }
       
            })
        }
        if(markers.length>0){
            this.initMap(markers)
        }

    },

    methods: {
        async initMap(markers) {
            // console.log("initMap",this.current);
			let payload={
				markerType:"product",
                notEditable:true,
                lat:markers[0].lat,
                lng:markers[0].lng,
                markers:markers
			}
			this.$provider.gmap.createMap('map', payload, (res) => {
				this.setLocation(res)
				
			});
		},
    },
};
</script>

<style></style>
